import { Usuario } from '../interfaces/usuario';
import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";
import { map } from "rxjs/operators";
import { PublicModule } from "../public.module";
import { AuthService } from "./auth.service";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Hotel } from '../interfaces/hotel';

@Injectable({
  providedIn: "root",
})
export class HotelService {
  public user: any;
  public empresa: any;
  public headers: Headers;
 public baseUrl: String = "https://roomservice-app.herokuapp.com/api";
  // public baseUrl: String = "http://localhost:8081/api";

  constructor(
    public http: HttpClient,
    private authService: AuthService
  ) {
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");
    this.headers.append("Authorization", this.authService.getToken());
    this.user = JSON.parse(localStorage.getItem("usuario")) || {};
  }

  /**
   * Trae una lista con todos los hoteles del sistema.
   */
  public listarHoteles(): Promise<{success: Boolean, hoteles: Hotel[], msg: String}> {
    let newHeaders = this.setHeaders();
    return new Promise((success, fail) => {
      this.http.get(`${this.baseUrl}/hotel`, {
        headers: newHeaders,
      }).subscribe((result: {success: Boolean, hoteles: Hotel[], msg: String}) => {
        success(result);
      }, err => {
        console.log(err);
        fail(err);
      });
    });
  }

  /**
   * Trae una lista con todos los hoteles del sistema.
   * Trae todos los hoteles a los cuales el usuario tiene acceso
   */
  public listarHotelesProveedor(): Promise<{success: Boolean, hoteles: Hotel[], msg: String}> {
    let newHeaders = this.setHeaders();
    return new Promise((success, fail) => {
      this.http.get(`${this.baseUrl}/hotel/proveedor`, {
        headers: newHeaders,
      }).subscribe((result: {success: Boolean, hoteles: Hotel[], msg: String}) => {
        success(result);
      }, err => {
        console.log(err);
        fail(err);
      });
    });
  }

  /**
   * Trae un hotel del backend por id
   * @param id el id del hotel que se quiere traer
   */
  public traerHotel({id: id}: {id: String}): Promise<{success: Boolean, msg: String, hotel: Hotel}> {
    let newHeaders = this.setHeaders();
    return new Promise((success, fail) => {
      this.http.get(`${this.baseUrl}/hotel/${id}`, {
        headers: newHeaders,
      }).subscribe((result: {success: Boolean, msg: String, hotel: Hotel}) => {
        success(result);
      }, err => {
        console.log(err);
        fail(err);
      });
    });
  }

  /**
   * Actualiza un hotel del backend por id
   * @param hotel el hotel que se quiere actualizar
   */
  public actualizarHotel({hotel: hotel}: {hotel: Hotel}): Promise<{success: Boolean, msg: String}> {
    let newHeaders = this.setHeaders();
    return new Promise((success, fail) => {
      this.http.put(`${this.baseUrl}/hotel/${hotel._id}`, hotel, {
        headers: newHeaders,
      }).subscribe((result: {success: Boolean, msg: String}) => {
        success(result);
      }, err => {
        console.log(err);
        fail(err);
      });
    });
  }

  /**
   * Crea un hotel en el bacjend
   * @param hotel el hotel que se quiere crear
   */
  public crearHotel({hotel: hotel}: {hotel: Hotel}): Promise<{success: Boolean, msg: String}> {
    let newHeaders = this.setHeaders();
    return new Promise((success, fail) => {
      this.http.post(`${this.baseUrl}/hotel`, hotel, {
        headers: newHeaders,
      }).subscribe((result: {success: Boolean, msg: String}) => {
        success(result);
      }, err => {
        console.log(err);
        fail(err);
      });
    });
  }

  /**
   * Se crean los headers con el token de el auth service
   */
  public setHeaders() {
    return new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.authService.getToken(),
    });
  }
}

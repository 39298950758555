import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";
import { map } from "rxjs/operators";
import { tokenNotExpired } from "angular2-jwt";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { PublicModule } from "../public.module";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public authToken: any;
  public user: any;
  public headers: HttpHeaders;
  public baseUrl: String = "https://roomservice.imagineapps.co/";
  // public baseUrl: String = "http://localhost:8081/";
  constructor(public http: HttpClient) {
    this.headers = new HttpHeaders();
    this.headers.append("Content-Type", "application/json");
    this.user = JSON.parse(localStorage.getItem("usuario")) || {};
  }

  public authenticateUser(user) {
    return this.http
      .post(this.baseUrl + "users/authenticate", JSON.stringify(user), {
        headers: this.headers,
      });
  }

  public storeUserData(token, user) {
    localStorage.setItem("id_token", token);
    localStorage.setItem("usuario", JSON.stringify(user));
    this.authToken = token;
    this.user = user;
  }

  public forgot(email) {
    const obj = { email };
    return this.http
      .post(this.baseUrl + "users/forgot", JSON.stringify(obj), {
        headers: this.headers,
      });
  }

  public change(email, password, passwordNew) {
    return this.http
      .post(this.baseUrl + "users/reset", { email: email, password: passwordNew }, {
        headers: this.headers,
      });
  }

  public loadToken() {
    const token = localStorage.getItem("id_token");
    this.authToken = token;
  }
  public logout() {
    this.authToken = null;
    this.user = {};
    localStorage.clear();
  }
  public getToken() {
    this.loadToken();
    return this.authToken;
  }
  public loggedIn() {
    return tokenNotExpired("id_token");
  }
  public getUser() {
    return this.user;
  }

  /**
   * Crea un usuario en el sistema
   * @param success indica si la función guardó el usuario
   * @param msg el mensaje de error que envía el backend 
   */
  public crearUsuario({usuario: usuario}): Promise<{success: Boolean, msg: String}> {
    return new Promise((success, fail) => {
      this.http.post(`${this.baseUrl}users/register`, usuario).subscribe((result: any) => {
        success(result);
      }, err => {
        fail(err);
      });
    });
  }
}

import { Injectable } from "@angular/core";
import * as S3 from "aws-sdk/clients/s3";

@Injectable({
    providedIn: "root",
})
export class FileUploadService {

    /** Configuración del bucket de S3 */
    bucket: S3 = new S3({
        accessKeyId: "AKIAUT7IXVID77RSYJ5F",
        secretAccessKey: "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",
        region: "us-east-1"
    });

    constructor(
    ) {
    }

    /**
     * Sube un archivo a un bucket de S3
     * @param archivo 
     */
    uploadFile(archivo): Promise<{ success: Boolean, url?: String, msg?: String }> {
        var params = {
            Bucket: "roomservice-recursos",
            ACL: "public-read",
            Key: this.createId(8) + archivo.name.trim(),
            ContentType: archivo.type,
            Body: archivo
        };
        var options = {
            partSize: 10 * 1024 * 1024,
            queueSize: 1,
            ACL: "public-read"
        };
        return new Promise((success, error) => {
            this.bucket.upload(params, options, function (err, data) {
                if (err) {
                    error({ success: false, msg: 'No se pudo cargar la foto' });
                }
                success({ success: true, url: data.Location.toString() });
            });
        });
    }

    /**
     * Crea un id para el archivo que se va a subir
     * @param length longitud del id que se va a crear
     */
    createId(length) {
        var result = "";
        var characters =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

}